import { PropsWithChildren, createContext } from 'react';
import { EnvContext, PublicEnv } from '~/utils/env';
import {
  useReferralCodeContextValue,
  ReferralCodeContext,
} from '~/utils/referralCode';

type GlobalsContextType = {
  analyticsClientId: string | null;
};

export const GlobalsContext = createContext<GlobalsContextType>({
  analyticsClientId: null,
});

interface Props extends PropsWithChildren {
  env: PublicEnv | null;
  analyticsClientId: string | null;
}

export default function GlobalsProvider({
  env,
  children,
  analyticsClientId,
}: Props) {
  const referralCodeValue = useReferralCodeContextValue();

  return (
    <EnvContext.Provider value={env}>
      <ReferralCodeContext.Provider value={referralCodeValue}>
        <GlobalsContext.Provider
          value={{ analyticsClientId: analyticsClientId }}
        >
          {children}
        </GlobalsContext.Provider>
      </ReferralCodeContext.Provider>
    </EnvContext.Provider>
  );
}
